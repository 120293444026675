import * as React from 'react';
import { Container, Grid } from '@mui/material';

import Env from 'common/src/env';
import { WILDCARD_STATE } from 'common/src/models/event/eventTemplate';
import useAppSelector from '../../hooks/useAppSelector';
import { useEventTemplates } from '../../hooks/useResource';
import { selectEventTemplateLoadStatus } from '../../redux/slices/event';
import { selectStateFilterWithDefault } from '../../redux/slices/setting';

import { Loading } from 'common/src/components/base';
import EventListingCard from '../../components/EventListingCard';

interface IProps {}
const PastEventsTab: React.FC<IProps> = () => {
  const allEventTemplates = useEventTemplates(/* includePastEvents */ true);

  const stateFilter = useAppSelector(selectStateFilterWithDefault);
  const eventTemplateLoadStatus = useAppSelector(selectEventTemplateLoadStatus);

  const now = Date.now();
  const effectiveEventTemplates = Object.values(allEventTemplates)
    .filter((eventTemplate) =>
      !eventTemplate.archived && (Env.shouldShowWIPFeatures() || eventTemplate.published),
    )
    .filter((eventTemplate_) => eventTemplate_.visible)
    .filter((eventTemplate) => {
      if (eventTemplate.location.state.trim().toLowerCase() === stateFilter) {
        return true;
      }
      if (eventTemplate.additionalAdvertisedStates.includes(WILDCARD_STATE)) {
        return true;
      }
      if (eventTemplate.additionalAdvertisedStates.some((state) => state.trim().toLowerCase() === stateFilter)) {
        return true;
      }
      return false;
    })
    .filter((eventTemplate) => now > eventTemplate.payload.hostedEventPayload.registrationToTs)
    // Sort from most to least recent (ts from highest to lowest)
    .sort((e1, e2) => e2.eventFromTs - e1.eventFromTs);

  return (
    <Grid container columnSpacing={20} rowSpacing={20}>
      {
        eventTemplateLoadStatus === 'full' ?
          effectiveEventTemplates.map((eventTemplate) => (
            <Grid item key={eventTemplate.id} sm={12} md={6} lg={4} display='flex'>
              <EventListingCard eventTemplateId={eventTemplate.id} eventTicketInventory={null} />
            </Grid>
          )) :
          <Container><Loading /></Container>
      }
    </Grid>
  );
};

export default PastEventsTab;
